import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText, Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FlowerIcon from '@mui/icons-material/FilterVintage';
import CheckIcon from '@mui/icons-material/CheckCircle';

// import page1 from './Facial_Menu_Page1.png';
// import page2 from './Facial_Menu_Page2.png';
import scalpOilPic from '../../ScalpOil_1oz.jpg';
import comingSoon from '../../ProductImageComingSoon.png';
import giftCardPic from '../../giftCard.jpg';
import Views from '../Views';

const useStyles = makeStyles((theme) => ({
  paperRoot: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      fontWeight: 'bold',
      fontSize: '18px',
    },
  },
  content: {
    paddingLeft: theme.spacing(5),
  },
  productPic: {
    width: '250px',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function Product({ title, href, src }) {
  const classes = useStyles();
  return (
    <div style={{ margin: '10px' }}>
      <a href={href} rel="noreferrer" target="_blank">
        <img src={src} alt={title} className={classes.productPic} />
      </a>
      <Typography sx={{ textAlign: 'center' }}>{title}</Typography>
    </div>
  );
}

function Service({ title, price, time, description }) {
  const classes = useStyles();
  return (
    <Paper classes={{ root: classes.paperRoot }} elevation={6}>
      <div className={classes.header}>
        <Typography>{title}</Typography>
        <Typography>${price}</Typography>
      </div>
      <div className={classes.content}>
        <Typography>
          {description}
        </Typography>
        {time && (
          <Typography sx={{ fontWeight: 'bold' }}>
            {time} Minutes
          </Typography>
        )}
      </div>
    </Paper>
  );
}

const services = [
  {
    title: '60 Minute Customized Facial',
    price: 140,
    description: `
    A focused session for skin rejuvenation and relaxation.
    `,
  },
  {
    title: '90 Minute Customized Facial',
    price: 210,
    // time: 60,
    description: `
    A comprehensive facial with extended treatments and massage.
    `,
  },
  {
    title: '2 Hour Customized Facial',
    price: 280,
    description: `
    An immersive skin and wellness experience with in-depth care.
    `,
  },
  {
    title: '75 Minute Head Spa',
    price: 175,
    description: `
    A deeply relaxing, scalp-focused treatment designed to nourish your scalp, relieve tension, and promote healthy circulation.
    Ideal for those experiencing scalp dryness, buildup, or stress-related tension.
    
    Includes:
    Microscope scalp analysis (before & after)
    Soothing scalp prep with toner & jade comb massage
    Warm oil massage for scalp, neck & shoulders
    Deep cleansing scrub (sea salt & tea tree oil)
    Gentle shampoo & post-treatment scalp analysis
    `,
  },
  {
    title: 'Dry Head Spa',
    price: 70,
    time: 30,
    description: `
    Enhance your facial session with this deeply relaxing scalp therapy:

    Aromatherapy scalp massage

    Application of nourishing, non-oily scalp care products

    Gentle pressure techniques to ease tension & promote circulation

    A calming, sensory experience to complement your facial


    Available only as an add-on to facial appointments.
    `,
  },
];

const products = [
  {
    href: 'https://squareup.com/gift/MLJTJR22FQ949/order',
    title: 'Gift Card',
    src: giftCardPic,
  },
  {
    href: 'https://clarezaskin.square.site/product/pre-cleanse-scalp-oil-4oz/33?cs=true&cst=popular ',
    title: 'Scalp Oil',
    src: scalpOilPic,
  },
  {
    href: 'https://clarezaskin.square.site/product/face-cleansing-oil-4oz/34?cp=true&sa=true&sbp=false&q=false',
    title: 'Face Cleanse Oil',
    src: comingSoon,
  },
];

export default function Services() {
  // const imgStyle = {
  //   width: '100%',
  //   height: 'auto',
  // };
  const classes = useStyles();
  return (
    <>
      <Views.CenterColumn title="Shop">
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          {products.map(p => (
            <Product {...p} key={p.href} />
          ))}
        </div>
      </Views.CenterColumn>
      <Views.CenterColumn title="Services">
        <div className={classes.listRoot}>
          {services.map(s => {
            if (s.heading) {
              return (
                <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>{s.title}</Typography>
              );
            }
            return (
              <Service key={s.title} {...s} />
            );
          })}
        </div>
      </Views.CenterColumn>
      <Views.CenterColumn title="Customized Facials">
        <Typography>
          Each facial is designed to meet your skin’s needs on the day of your appointment.
          No need to select treatments — simply choose the duration, and I will curate the most beneficial experience for you.
        </Typography>
        <Typography>
          Your facial may include:
          <List>
            {[
              'Double cleansing',
              'High-frequency extractions',
              'Cold therapy',
              'Buccal massage (intraoral facial massage to sculpt, relieve tension & TMJ relief)',
              'Dermaplaning (if suitable)',
              'Lifting facial massage',
              'Korean ampoule infusions (including Salmon D.N.A., Hyaluronic Acid, Peptides)',
              'Nano Infusion Facial Therapy',
              'Carboxy Therapy',
              'V-Tox “Facelift in a Bottle”',
              'Green Sea Peel (pregnancy-safe resurfacing)',
              'Korean Chemical Peels (for acne, pigmentation & rejuvenation)',
            ].map(text => (
              <ListItem key={text}>
                <ListItemIcon sx={{ color: 'green' }}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText>
                  {text}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Typography>
      </Views.CenterColumn>
      <Views.CenterColumn title="Why Korean Skincare?">
        <Typography>
          At the heart of Clareza Skin is the philosophy of Korean skincare — an approach rooted in gentle,
          intentional care and long-term skin health.
        </Typography>
        <Typography>
          Korean skincare emphasizes:
          <List>
            {[
              'Deep Hydration & Barrier Protection',
              'Glowing, Healthy Skin Without Aggressive Treatments',
              'Layered, High-Performance Ingredients',
              'Relaxation & Skin Wellness in Every Step',
            ].map(text => (
              <ListItem key={text}>
                <ListItemIcon>
                  <FlowerIcon />
                </ListItemIcon>
                <ListItemText>
                  {text}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Typography>
        <Typography>
          Each session blends advanced Korean products and technology with massage techniques designed to improve circulation,
          relieve tension, and support radiant, balanced skin.
        </Typography>
      </Views.CenterColumn>
      <Views.CenterColumn title="Featured Treatment: Salmon D.N.A. Facial">
        <Typography>
          One of my signature offerings is the Salmon D.N.A. Facial,
          utilizing polydeoxyribonucleotide (PDRN) — an advanced ingredient derived from salmon DNA,
          known in Korean skincare for its powerful regenerative properties.
        </Typography>
        <Typography>
          Benefits of Salmon D.N.A. Therapy:
          <List>
            {[
              'Stimulates collagen production',
              'Enhances hydration, elasticity, and texture',
              'Repairs and strengthens the skin barrier',
              'Reduces fine lines and visible signs of aging',
              'Promotes healthy, glowing skin',
            ].map(text => (
              <ListItem key={text}>
                <ListItemIcon>
                  <FlowerIcon />
                </ListItemIcon>
                <ListItemText>
                  {text}
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </Typography>
        <Typography>
          This treatment is included in your facial when appropriate, based on your skin’s current needs.
        </Typography>
      </Views.CenterColumn>
    </>
  );
}
