import React from 'react';
import { CssBaseline, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import themes from './themes';
import { AppContextProvider, AppContext, useAppContext } from './context/AppContext';
import Button from './components/Button';
import ImageUnderlay from './components/ImageUnderlay';
import AboutMe from './components/AboutMe';
import ContactMe from './components/ContactMe';
import Services from './components/Services';
import Header from './components/Header';
import Footer from './components/Footer';
import NavBar from './components/NavBar';
import Gallery from './components/Gallery';
import VideoOverlay from './components/VideoOverlay/VideoOverlay';

import frontPageImg from './frontPage.jpg';
import GoogleReviews from './components/GoogleReviews/GoogleReviews';

const useStyles = makeStyles((theme) => ({
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  header1: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '50px!important',
    },
  },
  header2: {
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px!important',
    },
  },
  textSecondaryContrast: { color: theme.palette.secondary.contrastText },
  mobileVideo: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function BookWithMe() {
  const classes = useStyles();
  const { onBookClick } = useAppContext();
  return (
    <ImageUnderlay src={frontPageImg} alt="Facial">
      <div style={{ textAlign: 'center' }}>
        <Typography className={classnames(classes.header1, classes.textSecondaryContrast)}>
          Innovative Treatments
        </Typography>
        <Typography className={classnames(classes.header1, classes.textSecondaryContrast)}>
          in an intimate environment
        </Typography>
        <Button sharp onClick={onBookClick} color="primary" variant="contained" pad={2}>
          Book With Me
        </Button>
      </div>
    </ImageUnderlay>
  );
}

const frontVidSrc = 'https://storage.googleapis.com/clarezaskin_site/site-content/headSpa2.mp4';
const frontVidType = 'video/mp4';
function HomeView() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <NavBar />
      <VideoOverlay src={frontVidSrc} type={frontVidType} />
      <video className={classes.mobileVideo} muted controls autoPlay>
        <source src={frontVidSrc} type={frontVidType} />
        Your browser does not support the video tag.
      </video>
      <GoogleReviews cyclePeriod={7000} minRating={4} />
      <BookWithMe />
      <Footer />
    </React.Fragment>
  );
}

const viewComponentMap = {
  '/app/about': AboutMe,
  '/app/contact': ContactMe,
  '/app/services': Services,
  '/app/gallery': Gallery,
};

function View() {
  const { view } = React.useContext(AppContext);
  const { url: viewUrl } = view || {};
  let viewCompKey = (viewUrl || '').split('/').filter(x => !!x).slice(0, 2).join('/');
  viewCompKey = `/${viewCompKey}`;
  const showHome = !viewComponentMap[viewCompKey];
  return (
    <>
      <div style={{ display: showHome ? '' : 'none' }}>
        <HomeView />
      </div>
      {Object.keys(viewComponentMap).map(key => {
        const Comp = viewComponentMap[key];
        return (
          <div key={key} style={{ display: viewCompKey === key ? '' : 'none' }}>
            <Comp />
          </div>
        );
      })}
      <div style={{ display: showHome ? 'none' : '' }}>
        <Footer
          hideMap={viewUrl && viewUrl.includes('contact')}
        />
      </div>
    </>
  );
}

function Layout() {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Header />
      <div className={classes.fakeToolbar} />
      <View />
    </React.Fragment>
  );
}

function App() {
  return (
    <ThemeProvider theme={themes.default}>
      <CssBaseline enableColorScheme />
      <AppContextProvider>
        <Layout />
      </AppContextProvider>
    </ThemeProvider>
  );
}

export default App;
